.answer {
  background: #f9f9f9;
  margin-bottom: 15px;
  padding: 10px;

  border: solid 1px lightgrey;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;

  transition: border 0.2s ease-out;
}

.answer:hover {
  border: solid 1px #0071bc;
}

.answer-feedback {
  margin-top: 10px;
}

.answer-selected {
  background: #ffffff !important;
}

.answer-text {
  font-size: 18px;
}

.bolder {
  font-weight: bolder;
}

.correct {
  border-color: #07e200 !important;
}

#complete {
  grid-column: body-col;
}

.exception {
  grid-column: 2;
  display: grid;
  font-size: 20px;
  font-style: italic;
  color: #bf2639;
}

.incorrect {
  border-color: #fc3605 !important;
}

.gloss-menu {
  position: relative;
}

.gloss-flashcard-options {
  display: grid;

  margin-top: 10px;

  border-top: solid 0.5px lightgrey;

  grid-template-columns: auto auto;
}

.gloss-flashcard-options > div:nth-child(1) {
  margin-top: 5px;
  grid-column: 1 / 3;
}

.gloss-flashcard-options div.cursor {
  color: #00478f;
}

.gloss-flashcard-options div.cursor:hover {
  text-decoration: underline;
}

.gloss-overlay {
  display: grid;
  position: absolute;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  grid-template-columns: auto;
  grid-template-rows: auto auto auto;

  margin-top: 5px;

  z-index: 2;
  max-width: 200px;

  background: #ffffff;

  font-family: 'Open Sans';
  font-size: 14px;

  padding: 5px;

  border: solid 0.5px lightgrey;
  border-radius: 2px;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
}

#nav {
  grid-column: 2;
  display: grid;

  padding-top: 25px;
  padding-bottom: 50px;

  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));

  /* grid-template-rows: minmax(25px, 50px); */
  row-gap: 15px;
  align-items: center;
}

#nav > a {
  text-decoration: none;
}

#nav > a > span {
  display: block;

  text-align: center;
  color: white;

  background-color: #0054a6;

  cursor: pointer;

  margin-right: 5px;
  transition: background 0.3s ease-out;
}

#nav > a > span:hover {
  background-color: #00458a;
}

.nav > div {
  display: inline-block;

  cursor: pointer;

  margin: 15px;

  background: #f9f9f9;
  text-align: center;

  padding: 2px;
  border: solid 1px white;
  border-radius: 5px;
}

#nav > div {
  text-align: center;
  color: white;
  background-color: #0054a6;

  cursor: pointer;

  margin-right: 5px;
  transition: background 0.3s ease-out;
}

#title {
  font-family: 'EB Garamond';
  font-size: 32px;
}

#questions {
  display: grid;
  padding-top: 15px;

  border-top: rgb(238, 238, 238) solid 1px;

  grid-template-columns: auto;
  grid-row-gap: 50px;
  row-gap: 50px;
}

.question-body {
  font-size: 18px;
  margin-bottom: 30px;
}

.reader-question {
  display: grid;
  grid-template-columns: auto;
}

.start-btn {
  grid-column: 1 / 3;
}

#text-section {
  display: grid;

  grid-template-columns: 10% [body-col] auto 10%;
  grid-template-rows: auto [body-row] auto;

  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 1.7;
  letter-spacing: 0;

  padding-top: 50px;
}

#text-body {
  grid-column: body-col;
  grid-row: body-row;

  display: grid;

  grid-template-rows: auto auto auto;
}

#text-body > #text {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

#text-conclusion {
  grid-column: 2;
  display: grid;

  align-items: center;
  justify-items: center;
}

#text-intro {
  grid-column: 2;
  display: grid;

  margin-top: 100px;

  align-items: center;
  justify-items: center;
}

#text-score {
  display: grid;
  grid-column: 2;

  margin: 50px 15px 15px 15px;
}

#guide-title {
  margin-top: 20px;
  font-family: 'EB Garamond';
  font-size: 2rem;
}

.translations {
  padding-top: 10px;
  border-top: solid 0.5px lightgrey;
}

.gloss-overlay {
  display: grid;
  position: absolute;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  grid-template-columns: auto;
  grid-template-rows: auto auto auto;

  margin-top: 5px;

  z-index: 2;
  max-width: 200px;

  background: #ffffff;

  font-family: 'Open Sans';
  font-size: 14px;

  padding: 5px;

  border: solid 0.5px lightgrey;
  border-radius: 2px;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .header {
    display: flex;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
  }

  .footer_items {
    margin-top: 0;
  }

  #text-section {
    grid-template-columns: 7% [body-col] auto 7%;
    padding-top: 0;
  }

  #nav {
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));

    row-gap: 15px;
  }
}
